.row > .column-gallery {
  padding: 8px 8px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.row {
  padding: 10px;
  width: 100%;
  place-content: center;
  text-align: center;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.column-gallery {
  float: left;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  margin-top: -25px;
  width: 90%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}

.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.row img,
.mySlides img {
  width: 100%;
}

.row img {
  object-fit: cover;
}

@media screen and (max-width: 645px) {
  .row img {
    width: 100px;
    height: 100px;
  }
}

.mySlides {
  display: block;
  text-align: center;
  background: black;
}

.mySlides img {
    height: 85vh;
    object-fit: scale-down;
}

.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  opacity: 0.5;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

#caption {
  font-size: small;
  font-family: 'nunito'
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gallery {
  column-count: 5;
  --webkit-column-count: 5;
  --moz-column-count: 5;
  gap: .5rem;
  padding: 3rem;
}

.image img {
  height: auto;
  width: 100%;
  margin-bottom: .5rem;
}

@media screen and (max-width: 1210px) {
  .gallery {
    column-count: 4;
    --webkit-column-count: 4;
    --moz-column-count: 4;
  }
}

@media screen and (max-width: 810px) {
  .gallery {
    column-count: 3;
    --webkit-column-count: 3;
    --moz-column-count: 3;
  }
}

@media screen and (max-width: 500px) {
  .gallery {
    column-count: 2;
    --webkit-column-count: 2;
    --moz-column-count: 2;
  }
}

@media screen and (max-width: 400px) {
  .gallery {
    column-count: 1;
    --webkit-column-count: 1;
    --moz-column-count: 1;
  }
}
