.logo_header {
	background: #7993b2;
	/* text-align: center */
	display: flex;
	padding-left: 2%;
}

.logo_header img {
	padding-left: 5%;
	height: 111px;
	max-width: 100%;
	padding-top: 20px;
	padding-bottom: 2px;
}

.headerFloral {
	background: black;
	color: white;
	text-align: center;
	font-family: 'Nunito', 'Raleway';
}

.sticky {
	position: sticky;
	top: 0;
	z-index: 99;
}

.carousel {
	padding-top: 70px;
}

#firstC {
	height: 133vh !important;
}

.grid-container {
	display: flex;
	overflow: hidden;
	height: 100%;
	column-gap: 5px;
}

.grid-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* .column {
	flex: 1;
	text-overflow: hidden;
	margin-right: 5px;
}

.column:first-of-type {
	margin-left: 5px;
} */

.dropdown-menu {
	padding: 0 !important;
	border: none !important;
	border-radius: 0 !important;
}

.dropdown-item {
	background: #7993b2d6 !important;
	padding: 4px !important;
}


@media screen and (max-width: 1095px) {
	.column {
		width: 100%;
	}

	.grid-container {
		grid-gap: 0px;
		padding-right: 0px;
		padding-left: 0px;
		justify-content: center;
		flex-direction: column;
	}

	.logo_header img {
		height: 115px;
	}
}

.sidenav {
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.80);
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 60px;
	text-align: center;
}


.sidenav a {
	text-decoration: none;
	font-size: 15px;
	color: #818181;
	font-family: 'Nunito', 'Raleway', sans-serif;
	display: block;
	text-transform: uppercase;
	transition: 0.3s;
}

.sidenav a:hover {
	color: #f1f1f1;
}

.sidenav .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
	cursor: pointer;
}

#main {
	transition: margin-left .5s;
	padding: 16px;
}

@media screen and (max-height: 450px) {
	.sidenav {
		padding-top: 15px;
	}

	.sidenav a {
		font-size: 18px;
	}
}

#navbarNavAltMarkup {
	font-size: 14px;
	font-family: 'Nunito', 'Raleway', sans-serif;
	text-transform: uppercase;
	width: 100%;
	justify-content: center;
}

.navbar {
	background: #7993b2;
}

.navbar a {
	color: #dce2e2 !important;
}

.navbar a.active {
	font-weight: bold;
}

.navbar a:hover {
	font-weight: bold;
}

.navbar-brand {
	font-size: 30px;
	font-weight: bolder;
}

footer {
	padding-bottom: 20px;
	border-top: 3px solid #7993b2;
	font-family: 'Nunito', 'Raleway', sans-serif;
}

footer.contactFooter {
	margin-top: 0px !important;
	border-top: none;
}

footer .footcontainer {
	padding-top: 20px;
	text-align: center;
	color: #7993b2;
}

footer .footcontainer h8 {
	display: block;
	font-size: 12px;
}

footer .icons {
	padding-bottom: 7px;
}

.icons a:hover {
	text-decoration: none;
}

.section_welcome__main {
	padding-top: 110px;
}

.section_home__main {
	padding-top: 60px;
}

body {
	font-family: 'Nunito', 'Raleway', sans-serif;
}

.split {
	height: 100vh;
	width: 50%;
	position: fixed;
	z-index: 1;
	top: 0;
	overflow-x: hidden;
	padding-top: 20px;
}

.right {
	right: 0;
}

.centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

figure {
	position: relative;
	float: left;
	overflow: hidden;
}

figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 1;
	aspect-ratio: 3/4;
}

figure figcaption {
	padding: 1.5em;
	color: #000;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	position: absolute;
	top: 15%;
	height: 70%;
	width: 60%;
	overflow-y: hidden;
}

figure .figcaption2 {
	color: white;
	/* font-size: 1.25em; */
	position: absolute;
	top: 15%;
	text-align-last: center;
	text-align: center;
}

.figcaption2 > a > button {
	height: 40px;
	width: 100px !important;
	color: white;
	border: 1px solid white;
	background: none;
}

figure figcaption div {
}

figure figcaption::before,
figure figcaption::after {
	pointer-events: none;
}

figure h2 {
	word-spacing: -0.15em;
}

figure h2 span {
	font-weight: 600;
}

figure h2,
figure p {
	margin: 0;
}

figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

figure p:first-of-type {
	border-top: 1px solid #000;
	padding-top: 25px !important;
}

figure.effect-sarah img {
	/* max-width: none; */
	width: -webkit-calc(100% + 20px);
	width: calc(100% + 20px);
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-10px, 0, 0);
	transform: translate3d(-10px, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid-container .column:last-child img {
	object-position: 50% 25%;
}

/* :hover */
figure.effect-sarah img {
	/* opacity: 0.5; */
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* :hover */
figure.effect-sarah figcaption {
	background-color: rgba(255, 255, 255, .85);
}

/* visibility: hidden; */
figure.effect-sarah h2 {
	position: relative;
	overflow: hidden;
	padding: 0.5em 0;
	font-size: 30px;
	font-family: 'EB Garamond';
	text-align: center;
}

figure.effect-sarah:hover h2 {
	visibility: visible;
}

figure.effect-sarah h2::after {
	color: white;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	content: '';
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

figure.effect-sarah:hover h2::after {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

figure.effect-sarah p {
	padding: 4px 0;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	font-size: 20px;
}

/* figure.effect-sarah:hover p { */
figure.effect-sarah p {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	text-align: center;
}

figcaption .credits {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	bottom: 0;
	position: absolute;
}

/* // :hover */
figure.effect-sarah .credits {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	text-align: center;
}

figure.effect-sarah {
	opacity: 1;
	background-color: #696969db;
	width: 100%;
	margin-bottom: 0px;
	font-family: 'Nunito', 'Raleway', sans-serif;
	display: flex;
	place-content: center;
}

iframe {
	width: 99.1vw;
	height: 2400px;
	border: none;
}

.column-about {
	padding: 5px;
	flex: 1;
	display: flex;
    flex-direction: column;
    align-items: center;

	&.left {
		text-align: center;
	}
}

.column-about.right p {
	padding: 0 14px 0 14px;
	width: 50%;
	margin-bottom: 100px;
    margin-top: 50px;
}

.credits {
	font-size: small;
}

.grey {
	background: #f8f9f9;
}

.column-about img {
	vertical-align: middle;
	height: 100%;
	object-fit: cover;
	border-style: none;
	width: 60%;
	height: auto;
	/* margin: 99px; */
}

.left {
	height: 100%;
}

.right {
	flex: 1.7;
	display: flex;
    justify-content: center;
}

.rtl {
	flex-direction: row-reverse;
}

@media screen and (min-width: 1400px) {
	.about {
		padding-top: 5%;
		padding-bottom: 5%;
	}
}

@media screen and (max-width: 800px) {
	.miniltr {
		direction: ltr;
	}

	.column-about {
		width: 100%;
		text-align: center;
	}

	.about {
		display: inline-block !important;
		
	}

	.right {
		max-width: 100%;
	}

	.column-about.right p {
		width: 100%;
	}

	iframe {
		height: 2475px;
	}
}

/* Clear floats after the columns */
.row-about:after {
	content: "";
	width: 100vw;
	display: table;
	clear: both;
}

.about {
	align-items: center;
	display: flex;
}

.gar {
	font-family: 'EB Garamond';
	font-size: 24px;
	/* color: #7993b2 */
}

.gar2 {
	font-family: 'EB Garamond';
	font-size: 34px;
	/* color: #7993b2 */
}

.sidenav-items {
	margin-top: 30%;
}

@font-face {
	font-family: 'Avenir';
	src: local('Avenir'), url(./fonts/avenir.woff2) format('woff');
}

.home_header {
	height: 1px;
	/* margin-top: 20px; */
	display: flex;
	place-content: center;
}

.home_title {
	font-family: 'Nunito', 'Raleway', sans-serif;
	color: white;
	align-self: center;
}

a.anchor {
	display: block;
	position: relative;
	top: -135px;
	visibility: hidden;
	scroll-behavior: smooth;
}

.fifty-pct {
	
}

* {
  scroll-behavior: smooth;
}

html {
	scroll-behavior: smooth;
}

html::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #F5F5F5;
}

html::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

html::-webkit-scrollbar-thumb {
	background-color: #000000;
}

.services {
	padding: 3rem 3rem 0 3rem;
	text-align: center;
}

/* .service-breakdown {
	margin-left: 1rem;
} */
